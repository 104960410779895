<!-- Nominees Details Widget (02) -->
<template>
  <div>
    <div
      class="row nominee-full-row"
      v-for="(nominee_input, nominee_index) in NomineeInfo"
      :key="nominee_index"
    >
      <div class="col">
        <div class="card-outline">
          <div
            class="row input-row"
            v-show="nominee_index || (!nominee_index && NomineeInfo.length > 0)"
          >
            <div class="col close-align">
              <button
                type="button"
                id="close"
                class="btn btn-sm btn-danger add-new-shadow remove-btn"
                @click="removeTplNominee(nominee_index)"
              >
                <font-awesome-icon icon="times" />
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <b-badge
                  pill
                  variant="info"
                  class="nominee-badge"
                >{{ $t("Nominee") }} {{ nominee_index + 1 }}</b-badge>
              </div>
            </div>
            <div class="row nominee-row">
              <div class="col-sm-4 col-md-4 col-lg-3">
                <b-form>
                  <label for="input-live">{{ $t("Name") }} <span class="required-mark">*</span></label>
                  <ValidationProvider :id="'Nominee Name ' + nominee_index"  name="Name" rules="required|minmax:3,60|alpha_spaces">
                    <div slot-scope="{ errors }">
                      <b-form-input
                        v-model.trim="nominee_input.nominee_name"
                        placeholder="Nominee Name"
                        class="text-uppercase"
                      ></b-form-input>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                  <div class="mt-2">
                    <b-form-text>
                      {{ $t("Name") }} :
                      {{ nominee_input.nominee_name }}
                    </b-form-text>
                  </div>
                </b-form>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-2">
                <b-form>
                  <label for="input-live">{{ $t("DateOfBirth") }} <span class="required-mark">*</span></label>
                  <ValidationProvider :id="'Nominee DOB ' + nominee_index" name="DOB" rules="required">
                    <div slot-scope="{ errors }">
                      <b-form-input
                        type="date"
                        id="date"
                        v-model.trim="nominee_input.nominee_dob"
                        @blur="
                      ageCalc(nominee_index);
                      sendChangedData();
                    "
                      ></b-form-input>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </b-form>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-2">
                <div class="form-group">
                  <label for="lbl_nominee_age">{{ $t("NomineeAge") }} <span class="required-mark">*</span></label>
                  <ValidationProvider :id="'Age ' + nominee_index" name="Age" rules="required|max_value:120">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtNomineeAge"
                        readonly
                        :name="'txtNomineeAge' + nominee_index"
                        v-model.trim="nominee_input.nominee_age"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-3" v-show="nominee_input.nominee_age > 17">
                <b-form>
                  <label for="input-live">{{ $t("NIC") }} <span class="required-mark">*</span></label>
                  <ValidationProvider v-if="nominee_input.nominee_age > 17"
                    name="Nic"
                    :id="'Nominee Nic ' + nominee_index"
                    :rules="{
                      required: true,
                      regex: /^(\d{9}?[V|X]{1}|\d{12})$/i
                    }"
                  >
                    <div slot-scope="{ errors }">
                      <b-form-input
                        type="text"
                        id="nic"
                        placeholder="NIC Number"
                        v-model.trim="nominee_input.nominee_nic"
                        class="text-uppercase"
                      ></b-form-input>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </b-form>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-2">
                <b-form>
                  <label for="input-live">{{ $t("Relationship") }} <span class="required-mark">*</span></label>
                  <ValidationProvider :id="'Nominee Relationship ' + nominee_index" name="Relationship" rules="required|minmax:3,60|alpha_spaces">
                    <div slot-scope="{ errors }">
                  <!-- <b-form-input
                    type="text"
                    id="relationship"
                    placeholder="Relationship"
                    v-model="nominee_input.nominee_relationship"
                    @change="sendChangedData()"
                    class="text-uppercase"
                  ></b-form-input> -->
                  <v-select
                    class="select2"
                    style="width: 100%;"
                    v-model="nominee_input.nominee_relationship"
                    name="Relationship"
                    :options="nomineeDrpOpt"
                    :reduce="(val) => val.code"
                    @change="sendChangedData()"
                  ></v-select>
                  <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </b-form>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-2">
                <b-form>
                  <label for="input-live">{{ $t("Percentage") }} <span class="required-mark">*</span></label>
                  <ValidationProvider :vid="'Nominee Perc ' + nominee_index" name="Percentage" rules="required|numeric|minmax_value:100,100">
                    <div slot-scope="{ errors }">
                      <b-form-input
                        type="text"
                        id="percentage"
                        placeholder="%"
                        v-model="nominee_input.nominee_percentage"
                      ></b-form-input>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </b-form>
              </div>
            </div>
            <div class="row" v-show="nominee_input.nominee_age < 18">
              <div class="col">
                <div class="card-style">
                  <div class="card-area">
                    <div class="row">
                      <div class="col">
                        <span>
                          <small>{{ $t("GuardianWording") }}</small>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-4 col-lg-4">
                        <b-form>
                          <label for="input-live">{{ $t("Name") }}</label>
                          <b-form-input
                            v-model.trim="nominee_input.guardian_name"
                            placeholder="Guardian Name"
                            class="text-uppercase"
                          ></b-form-input>
                        </b-form>
                      </div>
                      <div class="col-sm-4 col-md-4 col-lg-2">
                        <b-form>
                          <label for="input-live">{{ $t("Age") }}</label>
                          <b-form-input
                            type="text"
                            id="age"
                            placeholder="Age"
                            v-model="nominee_input.guardian_age"
                          ></b-form-input>
                        </b-form>
                      </div>
                      <div class="col-sm-4 col-md-4 col-lg-3">
                        <b-form>
                          <label for="input-live">
                            {{
                            $t("NomineeRelationship")
                            }}
                          </label>
                          <b-form-input
                            type="text"
                            id="relationship"
                            placeholder="Relationship"
                            v-model="nominee_input.guardian_relationship"
                            class="text-uppercase"
                            @change="sendChangedData()"
                          ></b-form-input>
                        </b-form>
                      </div>
                      <div class="col-sm-4 col-md-4 col-lg-3">
                        <b-form>
                          <label for="input-live">{{ $t("NIC") }}</label>
                          <ValidationProvider
                            name="NIC Number"
                            :rules="{
                              regex: /^(\d{9}?[V|X]{1}|\d{12})$/i
                            }"
                          >
                            <div slot-scope="{ errors }">
                              <b-form-input
                                type="text"
                                id="nic"
                                placeholder="NIC"
                                v-model="nominee_input.guardian_nic"
                                class="text-uppercase"
                              ></b-form-input>
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </b-form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row add-new">
      <div class="col">
        <button
          type="button"
          id="add_new"
          class="btn btn-sm btn-success add-new-shadow"
          @click="addTplNominee()"
          v-if="NomineeInfo.length <= 3"
        >
          <font-awesome-icon icon="plus" />Add New
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
import { extend } from "vee-validate";

export default {
  name: "nominee-details-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["NomineeInfo" , "nomineeDrpOpt"]),
  },
  methods: {
    ...mapActions("proposal", [
      "addNominee",
      "removeNominee",
      "draftProposalData",
      "getAge",
    ]),
    addTplNominee() {
      this.addNominee();
    },
    removeTplNominee(index) {
      if(index > 0){
        this.removeNominee(index);  
      }
      
    },
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    async ageCalc(index) {
      let payload = {
        dob: this.NomineeInfo[index].nominee_dob,
        type: "nominee",
        index: index,
      };

      await this.getAge(payload);
    },
  },
  mounted(){
    extend("percentage_sum_validator", {
      computesRequired: true,
      immediate:true,
      validate: value => {
        console.log(value);
        let totalPer = 0;
        for (let nominee in this.NomineeInfo) {
          totalPer += parseInt(this.NomineeInfo[nominee].nominee_percentage);
        }
        if(  parseInt(totalPer) == 100){
          return true;
        }else{
          return false;
        }
      },
      message: "Total nominee Percentage should be equal to 100"
    });
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92D127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.add-new {
  float: right;
  margin-top: 10px;
}

.close-align {
  text-align: right;
}

.remove-btn {
  margin-top: -15px;
  margin-right: -10px;
}

.nominee-row {
  margin-top: 5px;
}

.nominee-full-row {
  margin-top: 10px;
  margin-bottom: 25px;
}

.input-row {
  margin-bottom: -30px;
}

.add-new-shadow {
  box-shadow: none !important;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}

.card-area {
  padding: 15px;
}

.nominee-badge {
  padding-right: 1em !important;
  padding-left: 1em !important;
  padding: 0.55em 1em !important;
}
</style>

<!-- Language Data for Nominees Details Widget -->
<i18n>
{
  "en": {
    "Nominee": "Nominee",
    "Name": "Name",
    "DateOfBirth": "Date of Birth ( MM/DD/YYYY)",
    "NomineeAge": "Age",
    "Relationship": "Relationship",
    "NomineeRelationship": "Relationship to nominee",
    "Percentage": "Perc. %",
    "Age": "Age",
    "NIC": "NIC",
    "GuardianWording": "If the nominee is a minor, his/her guardian’s name"
  },
  "si": {
    "Nominee": "නාමිකයන්",
    "Name": "නම",
    "DateOfBirth": "උපන් දිනය ( MM/DD/YYYY)",
    "NomineeAge": "වයස",
    "Relationship": "යෝජකයා/රක්ෂිතයා සමඟ සම්බන්ධතාවය",
    "NomineeRelationship": "නාමිකයාට අති සම්බන්ධතාවය",
    "Percentage": "ප්‍රතිශතය %",
    "Age": "වයස",
    "NIC": "ජා.හැ.අංකය",
    "GuardianWording": "නාමිකයා බාලවයස්කරුවෙක් නම් ඔහුගේ/ ඇයගේ භාරකරුගේ නම"
  },
  "ta": {
    "Nominee": "பின்னுரித்தாளார்",
    "Name": "பெயர்",
    "DateOfBirth": "பிறந்த திகதி ( MM/DD/YYYY)",
    "NomineeAge": "வயது",
    "Relationship": "முன்மொழிபவர்/காப்பீட்டாளருடனான உறவு",
    "NomineeRelationship": "பின்னுரித்தாளருடனான உறவு முறை",
    "Percentage": "சதவிதம்",
    "Age": "வயது",
    "NIC": "தே. அ. அ. இலக்கம்",
    "GuardianWording": "பின்னுரித்தாளர் பராயமடையாதவராயின் அவரது பாதுகாவலரின் பெயர்"
  }
}
</i18n>
